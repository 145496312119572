import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, RouterModule } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { Category } from '../../../models/category.model';
import { StateService } from '../../../services/state/state.service';
import { TecdocService } from '../../../services/tecdoc/tecdoc.service';
import { CategoryNode } from '../../home/home.component';
import { SideNavComponent } from '../../side-nav/side-nav.component';
import { CategoryGridComponent } from '../category-grid/category-grid.component';
import { BasketService } from '../../../services/basket/basket.service';
import { PriceService } from '../../../services/price/price.service';

export interface Article {
  dataSupplierId: number;
  articleNumber: string;
  mfrId: number;
  articleCriteria: any[];
  articleLogisticsCriteria: any[];
  articleText: string[];
  comparableNumbers: any[];
  dataSupplierId2: number;
  genericArticles: any[];
  gtins: string[];
  images: any[];
  linkages: any[];
  links: any[];
  mfrName: string;
  misc: {
    additionalDescriptionId: number;
    additionalDescription: string;
    articleStatusId: number;
    quantityPerPackage: number;
    articleStatusDescription: string;
  };
  oemNumbers: any[];
  pdfs: any[];
  replacedByArticles: any[];
  replacesArticles: any[];
  totalLinkages: number;
  tradeNumbers: string[];
  tradeNumbersDetails: any[];
}

@Component({
  selector: 'app-category',
  standalone: true,
  imports: [
    RouterModule,
    CurrencyPipe,
    CommonModule,
    CategoryGridComponent,
    SideNavComponent,
  ],
  templateUrl: './category.component.html',
  styleUrl: './category.component.scss',
})
export class CategoryComponent implements OnInit, OnDestroy {
  items: Article[] = [];

  modelId?: number;
  categoryId?: number;
  showItems: boolean = false;
  category?: CategoryNode;
  page: number = 1;
  maxPages: number = 1;
  totalArticles: number = 0;
  state: any;
  private routeSub!: Subscription;
  prices?: Data;

  constructor(
    private route: ActivatedRoute,
    private tecdocService: TecdocService,
    private stateService: StateService,
    private basketService: BasketService,
    private priceService: PriceService
  ) {}

  ngOnInit(): void {
    this.stateService.currentState.subscribe((state) => {
      this.state = state;
    });
    this.stateService.updateState({ ...this.state, isCategory: true });
    this.page = 1;
    if (this.route.parent)
      this.routeSub = combineLatest([
        this.route.params,
        this.route.parent.params,
      ]).subscribe(([params, parentParams]) => {
        this.categoryId = params['id'];
        this.modelId = parentParams['modelId'];
        this.loadCategoryData();
      });
    else {
      this.routeSub = this.route.params.subscribe((params) => {
        this.categoryId = params['id'];
        this.loadCategoryData();
      });
    }
  }

  ngOnDestroy(): void {
    this.stateService.updateState({ ...this.state, isCategory: false });
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  loadCategoryData() {
    console.log('LLOOOOOAAAADDDDa');
    if (this.modelId) {
      this.tecdocService
        .getModelCategory({
          categoryId: this.categoryId,
          modelId: this.modelId,
          type: this.state.vehicleType,
        })
        .subscribe({
          next: (categories: CategoryNode[]) => {
            this.category = categories[0];
            if (
              categories[0].children?.length == 1 &&
              categories[0].children[0].assemblyGroupNodeId == this.categoryId
            ) {
              this.category = categories[0].children[0];
            }

            this.stateService.updateState({
              ...this.state,
              heading: `${this.category?.assemblyGroupName || ''} (${
                this.totalArticles
              })`,
            });
            this.getItems();
          },
          error: (error) =>
            console.error('There was an error loading categories', error),
        });
    } else if (this.categoryId) {
      this.tecdocService.getCategory(this.categoryId).subscribe({
        next: (categories: CategoryNode[]) => {
          this.category = categories[0];
          this.stateService.updateState({
            ...this.state,
            sideNavCategories: categories,
            heading: `${this.category?.assemblyGroupName || ''} (${
              this.totalArticles
            })`,
          });
          this.getItems();
        },
        error: (error) =>
          console.error('There was an error loading categories', error),
      });
    }
  }

  getItems() {
    if (this.categoryId && this.modelId) {
      this.tecdocService
        .getArticles({
          categoryId: this.categoryId,
          modelId: this.modelId,
          pageNumber: this.page,
        })
        .subscribe({
          next: (data) => {
            this.showItems = true;
            this.totalArticles = data.totalMatchingArticles;
            this.items = data.articles;
            this.maxPages = data.maxAllowedPage;
            this.items.forEach((item) =>
              item.images?.sort((a, b) => a.order - b.order)
            );
            const articleNumbers = this.items.map((item) => item.articleNumber);
            this.priceService.getPricesByNames(articleNumbers).subscribe({
              next: (data) => {
                console.log(data);
                this.prices = data;
              },
              error: (err) => {
                console.log(err);
              },
            });
            this.stateService.updateState({
              ...this.state,
              heading: `${this.category?.assemblyGroupName || ''} (${
                this.totalArticles
              })`,
            });
          },
          error: (error) =>
            console.error('There was an error loading items!', error),
        });
    } else if (this.categoryId) {
      this.tecdocService
        .getArticles({
          categoryId: this.categoryId,
          pageNumber: this.page,
        })
        .subscribe({
          next: (data) => {
            this.showItems = true;
            this.totalArticles = data.totalMatchingArticles;
            this.items = data.articles;
            this.maxPages = data.maxAllowedPage;
            this.items.forEach((item) =>
              item.images?.sort((a, b) => a.order - b.order)
            );
            const articleNumbers = this.items.map((item) => item.articleNumber);
            this.priceService.getPricesByNames(articleNumbers).subscribe({
              next: (data) => {
                console.log(data);
                this.prices = data;
              },
              error: (err) => {
                console.log(err);
              },
            });
            this.stateService.updateState({
              ...this.state,
              heading: `${this.category?.assemblyGroupName || ''} (${
                this.totalArticles
              })`,
            });
          },
          error: (error) =>
            console.error('There was an error loading items!', error),
        });
    }
  }

  addToCart(item: Article, quantity: String, event: Event) {
    this.removeRedirect(event);
    this.basketService.addItem({
      imageUrl:
        item.images && item.images.length
          ? item.images[0].imageURL100
          : '../../../../assets/placeholder.png',
      productId: item.articleNumber,
      name: item.mfrName + ' ' + item.articleNumber,
      price: this.getPrice(item) || 0,
      quantity: Number(quantity),
      car: this.state.car,
    });
  }

  getPrice(item: Article): number {
    if (!this.prices) return -1;
    return parseFloat(this.prices[item.articleNumber]);
  }

  removeRedirect(event: Event) {
    if (event) event.stopPropagation();
  }

  sortCategories(categories: Category[]): Category[] {
    let res = categories.sort((a, b) => {
      // Handle cases where name might be undefined or empty strings
      const nameA = a.name || '';
      const nameB = b.name || '';

      if (nameA < nameB) {
        return -1; // nameA comes first
      }
      if (nameA > nameB) {
        return 1; // nameB comes first
      }
      return 0; // names are equal
    });
    res.forEach((category) => {
      if (category.children && category.children.length) {
        category.children = this.sortCategories(category.children);
      }
    });
    return res;
  }

  previousPage() {
    if (this.page !== 1) {
      this.page = this.page - 1;
      this.getItems();
    }
  }

  nextPage() {
    if (this.page < this.maxPages) {
      this.page = this.page + 1;
      this.getItems();
    }
  }
}
