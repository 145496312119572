import { Component } from '@angular/core';
import { ActivatedRoute, Data, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { StateService } from '../../../services/state/state.service';
import { TecdocService } from '../../../services/tecdoc/tecdoc.service';
import { Article } from '../category/category.component';
import { CommonModule } from '@angular/common';
import { PriceService } from '../../../services/price/price.service';
import { BasketService } from '../../../services/basket/basket.service';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
})
export class SearchComponent {
  items: Article[] = [];
  searchQuery?: string;
  page: number = 1;
  maxPages: number = 1;
  totalArticles: number = 0;
  state: any;
  private routeSub!: Subscription;
  prices?: Data;

  constructor(
    private route: ActivatedRoute,
    private tecdocService: TecdocService,
    private stateService: StateService,
    private priceService: PriceService,
    private basketService: BasketService
  ) {}

  ngOnInit(): void {
    this.stateService.currentState.subscribe((state) => {
      this.state = state;
    });
    this.stateService.updateState({ ...this.state, isCategory: true });
    this.page = 1;
    this.routeSub = this.route.params.subscribe((params) => {
      this.searchQuery = params['searchQuery'];
      this.getItems();
    });
  }

  ngOnDestroy(): void {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  getItems() {
    if (this.searchQuery) {
      this.tecdocService
        .searchArticle({
          searchQuery: this.searchQuery,
          searchType: 10,
          pageNumber: this.page,
        })
        .subscribe({
          next: (data) => {
            console.log(data);
            this.totalArticles = data.totalMatchingArticles;
            this.items = data.articles;
            this.maxPages = data.maxAllowedPage;
            this.items.forEach((item) =>
              item.images?.sort((a, b) => a.order - b.order)
            );
            const articleNumbers = this.items.map((item) => item.articleNumber);
            this.priceService.getPricesByNames(articleNumbers).subscribe({
              next: (data) => {
                console.log(data);
                this.prices = data;
              },
              error: (err) => {
                console.log(err);
              },
            });
            this.stateService.updateState({
              ...this.state,
              heading: `${this.searchQuery || ''} (${this.totalArticles})`,
            });
          },
          error: (error) =>
            console.error('There was an error loading items!', error),
        });
    }
  }

  removeRedirect(event: Event) {
    if (event) event.stopPropagation();
  }

  previousPage() {
    if (this.page !== 1) {
      this.page = this.page - 1;
      this.getItems();
    }
  }

  nextPage() {
    if (this.page < this.maxPages) {
      this.page = this.page + 1;
      this.getItems();
    }
  }

  addToCart(item: Article, quantity: String, event: Event) {
    this.basketService.addItem({
      imageUrl:
        item.images && item.images.length
          ? item.images[0].imageURL100
          : '../../../../assets/placeholder.png',
      productId: item.articleNumber,
      name: item.mfrName + ' ' + item.articleNumber,
      price: this.getPrice(item) || 0,
      quantity: Number(quantity),
      car: this.state.car,
    });
  }

  getPrice(item: Article): number {
    if (!this.prices) return -1;
    return parseFloat(this.prices[item.articleNumber]);
  }
}
