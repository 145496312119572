<header class="flex flex-row justify-between items-center bg-neutral-900 pb-4 pt-2 px-6 shadow">
  <div class="flex items-center justify-between w-full">
    <div class="w-1/3 flex justify-start items-center">
      <a routerLink="shop" class="ml-10 mr-24">
        <img src="assets/logo.svg" alt="Logo" class="logo h-16">
      </a>
      <div class="flex flex-col text-gray-400 text-sm">
        <span><a href="mailto:info@arsiko.com">info&#64;arsiko.com</a></span>
        <span><a href="tel:+37122462117">+371 22462117</a></span>
      </div>
    </div>

    <nav class="w-1/3 flex justify-evenly items-center">
      <a routerLink="shop" class="text-appPrimary font-semibold px-3"
        routerLinkActive="font-bold underline decoration-2 underline-offset-4"
        [routerLinkActiveOptions]="{exact: true}">Veikals</a>
      <a routerLink="shop/about-us" class="text-appPrimary font-semibold px-3"
        routerLinkActive="font-bold underline decoration-2 underline-offset-4">Par
        mums</a>
      <a routerLink="shop/contacts" class="text-appPrimary font-semibold px-3"
        routerLinkActive="font-bold underline decoration-2 underline-offset-4">Kontakti</a>
    </nav>
    <div class="w-1/3 flex justify-end items-center mr-10">
      <div class="mr-5">
        <input type="text" placeholder="Meklēt..." [(ngModel)]="searchInput" (keydown.enter)="onSearch()">
        <button class="search-button" (click)="onSearch()">
          <i class="fa fa-search"></i>
        </button>
      </div>

      <div>
        <a routerLink="shop/checkout/cart" class="text-gray-400 hover:text-gray-500 mr-4"><i
            class="fa-solid fa-cart-shopping"></i></a>
        @if(authService.isLoggedIn()){
        <a routerLink="shop/profile" class="text-gray-400 hover:text-gray-500"><i class="fa-solid fa-user"></i></a>
        <span (click)="logout()" class="cursor-pointer text-neutral-400 hover:text-gray-500 ml-4">Iziet</span>
        }
        @if(!authService.isLoggedIn()){
        <span routerLink="/shop/login" class="cursor-pointer text-neutral-400 hover:text-gray-500">Ienākt</span>
        }

      </div>
    </div>
  </div>
</header>