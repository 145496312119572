<div class="basket-page-container">
    <h2>{{getHeading() | async}}</h2>
    <div class="flex justify-between">
        <router-outlet></router-outlet>

        <div class="order-summary shadow-md bg-neutral-50 rounded-md ml-3">
            <h3>Pasūtījuma apkopojums</h3>
            <p class="text-sm text-gray-500">{{basketItems.length}} prece{{basketItems.length > 1 ? 's' : ''}} grozā</p>
            <div class="order-details">
                <mat-divider class="!my-2"></mat-divider>
                <div class="delivery-section" (click)="toggleDropdown()">
                    <p>{{ deliveryOption.name }}</p>
                    <p class="text-sm text-gray-500">{{ deliveryOption.date }}</p>
                    <i class="text-gray-700 fa-solid fa-angle-up ml-1" *ngIf="isDropdownOpen"></i>
                    <i class="text-gray-700 fa-solid fa-angle-down ml-1" *ngIf="!isDropdownOpen"></i>
                </div>

                <!-- Dropdown Component -->
                <app-delivery-options *ngIf="isDropdownOpen" (optionSelected)="onDeliveryOptionSelected($event)">
                </app-delivery-options>
                <mat-divider class="!my-2"></mat-divider>
                <div class="order-pricing">
                    <p>Starpsumma: {{ total | currency: 'EUR' }}</p>
                    <p *ngIf="deliveryOption.price">Piegāde : {{ deliveryOption.price| currency: 'EUR' }}</p>
                    <p class="!mt-3">PVN 21%: {{ (total + deliveryOption.price) * 0.21 | currency: 'EUR' }}</p>
                    <p class="font-bold">Kopsumma: {{ total + deliveryOption.price| currency: 'EUR' }}</p>
                </div>
                <button class="checkout-button" (click)="submit()">Noformēt pasūtījumu</button>
                <button class="continue-shopping-button" (click)="back()">Turpināt iepirkties</button>
            </div>
        </div>
    </div>

</div>