<div class="flex flex-col bg-white  border rounded-md border-gray-300 shadow-sm h-auto w-full p-5">
    <div class="flex flex-row">
        <div class="flex flex-col w-1/3 justify-start items-center p-3">
            <img [src]="selectedImage ? selectedImage : '../../../../assets/placeholder.png'"
                class="h-60 object-contain min-w-20">
            <div class="flex flex-row justify-center items-center flex-wrap m-2 gap-2">
                @for (img of images; track $index) {
                <img [src]="img.imageURL200"
                    class="min-w-10 w-24 object-contain border p-1 aspect-square cursor-pointer"
                    (click)="selectImage($index)">
                }
            </div>

        </div>
        <div class="flex w-2/3 flex-row">
            <div class="flex flex-col gap-3 p-2 w-2/3">
                <div>Zīmols: {{item.mfrName}}</div>
                @if(item.articleNumber){
                <div>Artikula numurs: {{item.articleNumber}}</div>
                }
                @if(item.gtins && item.gtins[0]){
                <div>GTIN/EAN: {{item.gtins[0]}}</div>
                }
                @if(item.tradeNumbers && item.tradeNumbers.length){
                <div>Tirdzniecības numuri: <span class="cursor-pointer text-blue-500 hover:text-blue-700"
                        [routerLink]="'/shop/catalogue/search/' + tradeNumber"
                        *ngFor="let tradeNumber of item.tradeNumbers">{{tradeNumber}}</span></div>
                }
                <div class="">Produktu grupa: {{item.genericArticles[0].genericArticleDescription}}</div>

                @if(item.misc && item.misc.articleStatusDescription){
                <div>Statuss: {{item.misc.articleStatusDescription}}</div>
                }
                @if(item.misc && item.misc.quantityPerPackage){
                <div>Iepakojuma vienība: {{item.misc.quantityPerPackage}}</div>
                }

                <div *ngIf="item.articleCriteria.length">
                    <mat-divider></mat-divider>
                    <div class="flex flex-col mt-2">
                        <div class="font-semibold" *ngIf="item.articleCriteria && item.articleCriteria.length">Tehniskie
                            Parametri:</div>
                        @for(criteria of item.articleCriteria; track $index){
                        <div class="flex ml-3"><span class="mr-1">{{criteria.criteriaAbbrDescription ||
                                criteria.criteriaDescription}}:</span>
                            <span class="mr-2 flex-nowrap">{{criteria.formattedValue + (criteria.criteriaUnitDescription
                                ? '
                                ' +
                                criteria.criteriaUnitDescription : '')}};</span>
                        </div>
                        }
                    </div>
                </div>

                <div *ngIf="oeNumbers.length">
                    <mat-divider></mat-divider>
                    <div class=" mt-2"><span class="font-semibold">OE numuri</span>
                        <div *ngFor="let brand of oeNumbers" class="mb-2 ml-3">
                            {{brand.mfrName}} <br>
                            <div class="flex flex-wrap gap-2 ml-3">
                                <span class="ml-3 cursor-pointer text-blue-500  hover:text-blue-700"
                                    *ngFor="let number of brand.articleNumbers"
                                    [routerLink]="'/shop/catalogue/search/' + number">{{number}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="brands.length">
                    <mat-divider></mat-divider>
                    <div class="mt-2"><span class="font-semibold">Saderīgie transportlīdzekļi</span>
                        <div>
                            <span *ngFor="let brand of brands"
                                class="mb-2 ml-3 cursor-pointer whitespace-nowrap">{{brand.manuName}}</span>
                        </div>
                    </div>
                </div>

            </div>
            <div class="flex flex-col w-1/3 justify-start text-center items-center pr-6 mt-10" *ngIf="showPrice">
                <div class="flex items-center justify-center space-x-2 mb-4">
                    <input #quantityInput type="number" (click)="removeRedirect($event)"
                        class="w-12 text-center border border-gray-300 rounded" value="1" min="1" />
                </div>

                @if(getPrice() >= 0){
                <div class="flex flex-col w-full mb-8">
                    <span class="font-semibold text-lg">{{getPrice() | currency: 'EUR'}}</span>
                    <span>{{getPrice() * 0.79 | currency: 'EUR'}} (bez PVN)</span>
                </div>
                } @else {
                <span class="text-sm font-normal mb-3">Cenas nav</span>
                }
                <div class="flex justify-center w-20 items-center text-green-700 font-bold rounded border-amber-950 hover:bg-neutral-200 shadow-md border-2 h-fit p-2"
                    (click)="addToCart(quantityInput.value, $event)">
                    Pirkt</div>
            </div>
        </div>
    </div>
</div>