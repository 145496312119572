import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TecdocService {
  private baseUrl = `${environment.apiBaseUrl}`;
  private categories: any;

  constructor(private http: HttpClient) {}

  getCategories(): Observable<any[]> {
    if (!this.categories)
      this.categories = this.http.post<any[]>(
        `${this.baseUrl}/catalogue/categories`,
        {}
      );
    return this.categories;
  }

  getCategory(categoryId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/catalogue/categories/${categoryId}`);
  }

  getArticles(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/catalogue/articles`, data);
  }

  getArticle(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/catalogue/article`, data);
  }

  getArticleBrands(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/catalogue/article-brands`, data);
  }

  getBrands(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/catalogue/manufacturers`, data);
  }

  getModels(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/catalogue/models`, data);
  }

  getModelTypes(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/catalogue/models/types`, data);
  }

  getModelCategories(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/catalogue/model-categories`, data);
  }

  getModelCategory(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/catalogue/model-category`, data);
  }

  getCarDetails(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/catalogue/car-details`, data);
  }

  searchArticle(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/catalogue/search-article`, data);
  }
}
