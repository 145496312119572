import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TecdocService } from '../../../services/tecdoc/tecdoc.service';
import { retry } from 'rxjs';
import { Router } from '@angular/router';
import { StateService } from '../../../services/state/state.service';
import { extractYear, extractYearShort } from '../../../shared';

export interface CarManufacturer {
  favorFlag: number;
  linkingTargetTypes: string;
  manuId: number;
  manuName: string;
}

export interface CarModel {
  id: number;
  name: string;
  beginYearMonth: number;
  endYearMonth: number;
  count: number;
}

export interface CarType {
  value: string;
  name: string;
}

export interface ModelType {
  linkageTargetId: number;
  linkageTargetType: string;
  subLinkageTargetType: string;
  description: string;
  mfrId: number;
  mfrName: string;
  mfrShortName: string;
  vehicleModelSeriesId: number;
  vehicleModelSeriesName: string;
  beginYearMonth: string;
  endYearMonth: string;
  rmiTypeId: number;
  axleBodyTypes: any[];
  vehicleImages: any[];
  kbaNumbers: string[];
  driveTypeKey: number;
  driveType: string;
  bodyStyleKey: number;
  bodyStyle: string;
  valves: number;
  fuelMixtureFormationTypeKey: number;
  fuelMixtureFormationType: string;
  fuelTypeKey: number;
  fuelType: string;
  engineTypeKey: number;
  engineType: string;
  horsePowerFrom: number;
}

const CAR_TYPES: CarType[] = [
  { name: 'Vieglā automašīna', value: 'V' },
  { name: 'Komerctransports', value: 'O' },
];

@Component({
  selector: 'app-car-search',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './car-search.component.html',
  styleUrl: './car-search.component.scss',
})
export class CarSearchComponent implements OnInit {
  vehicleTypes: CarType[] = CAR_TYPES;
  brands: CarManufacturer[] = [];
  favouriteBrands: CarManufacturer[] = [];
  models: CarModel[] = [];
  modelTypes: ModelType[] = [];
  searchBrand: string = '';
  searchModel: string = '';
  searchType: string = '';
  selectedBrand?: CarManufacturer;
  selectedVehicleType!: CarType;
  selectedModel?: CarModel;
  selectedModelType?: ModelType;
  filteredBrands: CarManufacturer[] = [...this.brands];
  filteredModels: CarModel[] = [];
  filteredTypes: ModelType[] = [];
  showBrands: boolean = false;
  showModels: boolean = false;
  showModelTypes: boolean = false;
  showVehicleTypes: boolean = false;
  @ViewChild('brandInput') brandInput!: ElementRef;
  @ViewChild('modelInput') modelInput!: ElementRef;
  @ViewChild('typeInput') typeInput!: ElementRef;

  state: any;
  isModelDisabled: boolean = true;
  isTypeDisabled: boolean = true;

  constructor(
    private tecdocService: TecdocService,
    private router: Router,
    private stateService: StateService
  ) {}

  ngOnInit(): void {
    this.stateService.currentState.subscribe((state) => {
      this.state = state;
    });
    this.selectedVehicleType = this.vehicleTypes[0];
    this.tecdocService
      .getBrands({ type: this.selectedVehicleType.value })
      .subscribe({
        next: (data) => {
          this.brands = data;
          this.favouriteBrands = this.brands.filter((brand) => brand.favorFlag);
          console.log(this.favouriteBrands);
        },
        error: (err) => {
          console.log('Error while getting brands data', err);
        },
      });
  }

  clear() {
    this.searchBrand = '';
    this.searchModel = '';
    this.searchType = '';
  }

  onVehicleTypeChange() {
    this.showVehicleTypes = false;
    this.tecdocService
      .getBrands({ type: this.selectedVehicleType.value })
      .subscribe({
        next: (data) => {
          this.brands = data;
          this.favouriteBrands = this.brands.filter((brand) => brand.favorFlag);
          console.log(this.favouriteBrands);
          setTimeout(() => {
            this.brandInput.nativeElement.focus();
          }, 0);
        },
        error: (err) => {
          console.log('Error while getting brands data', err);
        },
      });
    this.filteredTypes = [...this.modelTypes];
    console.log(`Selected vehicle type: ${this.selectedVehicleType}`);
  }

  onBrandChange(brandId: number) {
    this.tecdocService
      .getModels({ type: this.selectedVehicleType.value, brandId })
      .subscribe({
        next: (data) => {
          this.models = data;
          this.filteredModels = [...this.models];
          this.isModelDisabled = false;
          setTimeout(() => {
            this.modelInput.nativeElement.focus();
          }, 0);
        },
        error: (err) => {
          console.log('Error while getting model data', err);
        },
      });
    this.showModels = false;
    this.isTypeDisabled = true;
    this.isModelDisabled = false;
    this.searchModel = '';
    this.searchType = '';
    this.showModelTypes = false;
    this.filteredTypes = [];
  }

  onModelChange(modelId: number) {
    this.tecdocService
      .getModelTypes({
        type: this.selectedVehicleType.value,
        brandId: this.selectedBrand?.manuId,
        modelId,
      })
      .subscribe({
        next: (data) => {
          this.modelTypes = data.linkageTargets;
          setTimeout(() => {
            this.typeInput.nativeElement.focus();
          }, 0);
        },
        error: (err) => {
          console.log('Error while getting model types data', err);
        },
      });
    this.filteredTypes = [...this.modelTypes];
    this.isTypeDisabled = false;
    this.showModelTypes = false;
    this.searchType = '';
    this.filteredTypes = [];
  }

  filterBrands() {
    this.showBrands = true;
    this.isModelDisabled = true;
    this.isTypeDisabled = true;
    this.searchBrand = '';
    this.searchModel = '';
    this.searchType = '';
    if (this.searchBrand == '') {
      this.filteredBrands = [...this.favouriteBrands];
      return;
    }
    this.filteredBrands = this.brands.filter((brand) =>
      brand.manuName.toLowerCase().includes(this.searchBrand.toLowerCase())
    );
  }

  filterModels() {
    this.showModels = true;
    this.isTypeDisabled = true;
    this.searchModel = '';
    this.searchType = '';
    this.filteredModels = this.models.filter((model) =>
      model.name.toLowerCase().includes(this.searchModel.toLowerCase())
    );
  }

  filterTypes() {
    this.showModelTypes = true;
    this.searchType = '';
    this.filteredTypes = this.modelTypes.filter((type) =>
      `${type.description} [${this.extractYear(
        type.beginYearMonth
      )}-${this.extractYear(type.endYearMonth)}]`
        .toLowerCase()
        .includes(this.searchType.toLowerCase())
    );
  }

  selectBrand(selectedBrand: CarManufacturer) {
    this.searchBrand = this.brands.filter(
      (brand) => brand.manuId == selectedBrand.manuId
    )[0].manuName;
    this.onBrandChange(selectedBrand.manuId);
    this.selectedBrand = selectedBrand;
    this.showBrands = false;
  }

  selectModel(selectedModel: CarModel) {
    this.searchModel = this.models.filter(
      (model) => model.id == selectedModel.id
    )[0].name;
    this.onModelChange(selectedModel.id);
    this.selectedModel = selectedModel;
    this.showModels = false;
  }

  selectType(type: ModelType) {
    this.searchType = `${type.description} [${this.extractYearShort(
      type.beginYearMonth
    )}-${this.extractYearShort(type.endYearMonth)}]`;
    this.filteredTypes = [...this.modelTypes];
    this.showModelTypes = false;
    this.selectedModelType = type;
    this.onSearch();
  }

  selectVehicleType(type: CarType) {
    this.selectedVehicleType = type;
    this.showBrands = false;
    this.searchBrand = '';
    this.filteredBrands = [...this.favouriteBrands];
    this.showModels = false;
    this.searchModel = '';
    this.filteredModels = [];
    this.showModelTypes = false;
    this.searchType = '';
    this.filteredTypes = [];
    this.onVehicleTypeChange();
  }

  hideBrands() {
    setTimeout(() => (this.showBrands = false), 100);
  }

  hideModels() {
    setTimeout(() => (this.showModels = false), 100);
  }

  hideVehicleTypes() {
    setTimeout(() => (this.showVehicleTypes = false), 100);
  }

  hideModelTypes() {
    setTimeout(() => (this.showModelTypes = false), 100);
  }

  onSearch() {
    console.log(
      `Searching for ${this.selectedVehicleType.name} - ${this.selectedBrand?.manuName} ${this.selectedModel?.name} ${this.selectedModelType?.description}`
    );
    this.router.navigate([
      `/shop/catalogue/model/${this.selectedVehicleType.value}/${this.selectedModelType?.linkageTargetId}`,
    ]);
  }
  extractYear = extractYear;
  extractYearShort = extractYearShort;

  extractYearNumber(date: number | undefined) {
    if (date == undefined) return '';
    return date.toString().slice(0, 4);
  }
}
