import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, catchError, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../../models/user.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseUrl = `${environment.apiBaseUrl}/customer`;
  private token: string | null = null;
  private userName: string | null = null;
  user?: User;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {}

  setToken(token: string): void {
    this.token = token;
    sessionStorage.setItem('token', token); // Optionally store the token in localStorage
  }

  setUserName(userName: string): void {
    this.userName = userName;
    localStorage.setItem('userName', userName); // Optionally store the token in localStorage
  }

  setUser(user: User): void {
    this.user = user;
    localStorage.setItem('user', JSON.stringify(user)); // Optionally store the token in localStorage
  }

  getToken(): string | null {
    return this.token || sessionStorage.getItem('token');
  }

  getUserName(): string | null {
    return this.userName || localStorage.getItem('userName');
  }

  getUser(): User | undefined {
    const user = localStorage.getItem('user');
    if (!this.user && user) {
      this.user = JSON.parse(user);
    }
    return this.user;
  }

  removeToken(): void {
    this.token = null;
    sessionStorage.removeItem('token');
  }

  removeUserName(): void {
    this.userName = null;
    localStorage.removeItem('userName');
  }

  removeUser(): void {
    this.user = undefined;
    localStorage.removeItem('user');
  }

  login(username: string, password: string): Observable<boolean> {
    return this.http
      .post<any>(`${this.baseUrl}/login`, { username, password })
      .pipe(
        map((response) => {
          if (response.accessToken) {
            this.setUser(response.user);
            this.setUserName(username);
            this.setToken(response.accessToken);
            return true;
          }
          return false;
        }),
        catchError((error) => {
          console.error(error);
          return of(false);
        })
      );
  }

  register(username: string, password: string): Observable<boolean> {
    return this.http.post<any>(`${this.baseUrl}/register`, {
      username,
      password,
    });
  }

  isLoggedIn(): boolean {
    return !!this.getToken();
  }

  logout() {
    this.removeUser();
    this.removeToken();
    this.removeUserName();
    const currentUrl = this.router.url;
    console.log(currentUrl)
    if (currentUrl.startsWith('/shop/profile')) {
      // Assuming '/profile' requires authentication
      this.router.navigate(['/']); // Redirect to home or another appropriate page
    }
  }
}
