import { Component } from '@angular/core';
import {
  BasketItem,
  BasketService,
} from '../../../services/basket/basket.service';
import { getClosestDate } from '../../../shared';
import { DeliveryOption } from '../cart.component';
import { MatDivider } from '@angular/material/divider';
import { DeliveryOptionsComponent } from '../delivery-options/delivery-options.component';
import { CommonModule, Location } from '@angular/common';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [MatDivider, DeliveryOptionsComponent, CommonModule, RouterModule],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss',
})
export class CheckoutComponent {
  basketItems: BasketItem[] = [];
  total: number = 0;
  todayDate: string = new Date().toLocaleDateString('lv-LV');
  deliveryOption: DeliveryOption = {
    name: 'Paņemšu pats birojā',
    date: getClosestDate(),
    price: 0,
  };
  quantities = Array.from({ length: 10 }, (_, i) => i + 1);
  isDropdownOpen: boolean = false;

  constructor(
    private basketService: BasketService,
    private location: Location,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.basketService.getBasket().subscribe((items) => {
      this.basketItems = items;
      this.total = this.basketService.getTotal();
    });
  }

  getHeading() {
    return this.basketService.getTitle();
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onDeliveryOptionSelected(option: any): void {
    this.deliveryOption = option;
    this.isDropdownOpen = false;
  }

  removeItem(productId: string): void {
    this.basketService.removeItem(productId);
  }

  updateQuantity(productId: string, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const quantityAsNumber = parseInt(inputElement.value, 10);
    if (quantityAsNumber <= 0) {
      this.removeItem(productId);
    } else {
      this.basketService.updateItemQuantity(productId, quantityAsNumber);
    }
  }

  removeRedirect(event: Event) {
    if (event) event.stopPropagation();
  }

  back() {
    this.location.back();
  }

  submit() {
    // this.router.navigate(['/shop/checkout/order']);
  }
}
