import { Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { ShopComponent } from './components/shop/shop.component';
import { CategoryComponent } from './components/shop/category/category.component';
import { ItemComponent } from './components/shop/item/item.component';
import { CartComponent } from './components/cart/cart.component';
import { authGuard } from './guards/auth.guard';
import { ProfileComponent } from './components/profile/profile.component';
import { LoginComponent } from './components/auth/login/login.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { ModelCategoriesComponent } from './components/shop/model-categories/model-categories.component';
import { CatalogueComponent } from './components/shop/catalogue/catalogue.component';
import { ModelComponent } from './components/shop/model/model.component';
import { SearchComponent } from './components/shop/search/search.component';
import { OrderComponent } from './components/cart/order/order.component';
import { CheckoutComponent } from './components/cart/checkout/checkout.component';
import { ReturnPolicyComponent } from './components/return-policy/return-policy.component';

export const routes: Routes = [
  {
    path: 'shop',
    component: ShopComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        pathMatch: 'full',
      },
      {
        path: 'checkout',
        component: CheckoutComponent,
        children: [
          {
            path: 'cart',
            component: CartComponent,
          },
          {
            path: 'order',
            component: OrderComponent,
          },
        ],
      },
      {
        path: 'catalogue',
        component: CatalogueComponent,
        children: [
          {
            path: 'model/:vehicleType/:modelId',
            component: ModelComponent,
            children: [
              {
                path: '',
                component: ModelCategoriesComponent,
              },
              {
                path: 'category/:id',
                component: CategoryComponent,
              },
              {
                path: 'item/:dataSupplierId/:articleId',
                component: ItemComponent,
              },
            ],
          },
          {
            path: 'category/:id',
            component: CategoryComponent,
          },
          {
            path: 'item/:dataSupplierId/:articleId',
            component: ItemComponent,
          },
          {
            path: 'search/:searchQuery',
            component: SearchComponent,
          },
        ],
      },

      {
        path: 'about-us',
        component: AboutUsComponent,
      },
      {
        path: 'return-policy',
        component: ReturnPolicyComponent,
      },
      {
        path: 'contacts',
        component: ContactsComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [authGuard],
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'shop',
  },
];
