<div class="w-full bg-neutral-100 p-3 px-44 min-h-screen">
    <div class="text-2xl text-amber-950 font-bold my-3">
        Kontakti
    </div>
    <div class="flex">
        <div class="w-auto justify-items-center mt-10 flex-grow">
            <p><a href="mailto:info@arsiko.com">info&#64;arsiko.com</a></p>
            <p><a href="tel:+37122642117">+371 22642117</a></p>
            <p><a href="tel:+37128164151">+371 28164151</a></p>
            <h3 class="mt-3 text-lg font-semibold">Juridiskā adrese:</h3>
            <p>Plakanciema Egles 18, Vaivadi, Olaines novads, LV-2127</p>
            <p>P Simona SIA</p>
            <p>LV40003991122</p>
        </div>
        <div class="w-1/2 h-96 border mr-8">
            <div class="h-full" #map></div>
        </div>
    </div>

</div>