import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Data, RouterModule } from '@angular/router';
import { ExternalID, Transport } from '../../../models/item.model';
import { ItemService } from '../../../services/item/item.service';
import { State, StateService } from '../../../services/state/state.service';
import { TecdocService } from '../../../services/tecdoc/tecdoc.service';
import { Article } from '../category/category.component';
import { PriceService } from '../../../services/price/price.service';
import { BasketService } from '../../../services/basket/basket.service';

@Component({
  selector: 'app-item',
  standalone: true,
  imports: [
    MatTableModule,
    CurrencyPipe,
    MatIconModule,
    CommonModule,
    RouterModule,
    MatDividerModule,
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
})
export class ItemComponent implements OnDestroy {
  item!: Article;
  selectedImage?: String;
  displayedColumnsTransport: string[] = [
    'brand',
    'model',
    'year',
    'volume',
    'power',
    'code',
  ];
  transportData: Transport[] = [];
  price!: number;
  priceVAT!: number;
  isExternalIdSectionOpen = true;
  state!: State;
  images: any[] = [];
  showPrice: boolean = true;
  brands: any[] = [];
  articleId!: string;
  oeNumbers: any[] = [];
  prices?: Data;

  constructor(
    private route: ActivatedRoute,
    private itemService: ItemService,
    private tecdocService: TecdocService,
    private stateService: StateService,
    private priceService: PriceService,
    private basketService: BasketService
  ) {}

  ngOnInit() {
    this.stateService.currentState.subscribe((state) => {
      this.state = state;
    });
    this.stateService.updateState({ ...this.state, isItem: true });
    this.route.params.subscribe((params) => {
      this.articleId = params['articleId'];
      const dataSupplierId = params['dataSupplierId'];
      this.tecdocService
        .getArticle({ articleId: this.articleId, dataSupplierId })
        .subscribe({
          next: (val) => {
            console.log(val);
            this.item = val.articles[0];
            this.getArticleBrands();
            this.oeNumbers = this.groupData(this.item.oemNumbers);
            console.log(this.oeNumbers);
            const articleNumbers = [this.item.articleNumber];
            this.priceService.getPricesByNames(articleNumbers).subscribe({
              next: (data) => {
                console.log(data);
                this.prices = data;
              },
              error: (err) => {
                console.log(err);
              },
            });
            this.stateService.updateState({
              ...this.state,
              heading: `${this.item.mfrName} | ${this.item.articleNumber}`,
            });
            this.images = this.item.images.filter(
              (image) => image.frame == null
            );
            console.log(this.images);
            if (this.images) {
              // val.images.sort((a, b) => a.order - b.order);
              this.selectedImage =
                this.images[0].imageURL800 || this.images[0].imageURL400;
            }
            // if (this.item.externalId)
            //   this.item.externalId = this.sortExternalIds(this.item.externalId);
            // this.price =
            //   (val.costPrice || 0) + ((val.costPrice || 0) * val.surcharge) / 100;
            // this.priceVAT = this.price + (this.price * (val.vatRate || 0)) / 100;
            // this.transportData = this.item.transport;
            if (val.externalId && val.externalId.length > 5)
              this.isExternalIdSectionOpen = false;
          },
          error: (err) => console.error(err),
        });
    });
  }

  ngOnDestroy(): void {
    this.stateService.updateState({ ...this.state, isItem: false });
  }

  toggleExternalIdSection() {
    this.isExternalIdSectionOpen = !this.isExternalIdSectionOpen;
  }

  selectImage(index: number) {
    if (this.images)
      this.selectedImage =
        this.images[index].imageURL800 || this.images[index].imageURL400;
  }

  addToCart(quantity: String, event: Event) {
    this.removeRedirect(event);
    this.basketService.addItem({
      imageUrl:
        this.item.images && this.item.images.length
          ? this.item.images[0].imageURL100
          : '../../../../assets/placeholder.png',
      productId: this.item.articleNumber,
      name: this.item.mfrName + ' ' + this.item.articleNumber,
      price: this.getPrice() || 0,
      quantity: Number(quantity),
      car: this.state.car,
    });
  }

  removeRedirect(event: Event) {
    if (event) event.stopPropagation();
  }

  getPrice(): number {
    if (!this.prices) return 0;
    return parseFloat(this.prices[this.item.articleNumber]);
  }

  sortExternalIds(categories: ExternalID[]): ExternalID[] {
    let res = categories.sort((a, b) => {
      // Handle cases where name might be undefined or empty strings
      const nameA = a.brand || '';
      const nameB = b.brand || '';

      if (nameA < nameB) {
        return -1; // nameA comes first
      }
      if (nameA > nameB) {
        return 1; // nameB comes first
      }
      return 0; // names are equal
    });
    return res;
  }

  getArticleBrands() {
    this.tecdocService
      .getArticleBrands({
        articleId: this.item.genericArticles[0].legacyArticleId || 0,
      })
      .subscribe({
        next: (val) => {
          console.log(val);
          this.brands = val.data.array;
        },
        error: (err) => console.error(err),
      });
  }

  groupData(data: any[]) {
    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.mfrName]) {
        acc[item.mfrName] = [];
      }
      acc[item.mfrName].push(item.articleNumber);
      return acc;
    }, {});

    const result = Object.keys(groupedData).map((mfrName) => ({
      mfrName,
      articleNumbers: groupedData[mfrName],
    }));

    // Sort the result by brand name (optional)
    result.sort((a, b) => a.mfrName.localeCompare(b.mfrName));
    return result;
  }
}
