import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CategoryNode } from '../../components/home/home.component';

const initialState: State = {
  sideNavCategories: [],
  heading: '',
  isModel: false,
  modelId: undefined,
  isItem: false,
  car: '',
  isCategory: false,
  isModelPage: false,
  vehicleType: 'V',
};

export interface State {
  sideNavCategories: CategoryNode[];
  heading: string;
  isModel: boolean;
  isItem: boolean;
  modelId?: number;
  vehicleType?: string;
  car: string;
  isCategory: boolean;
  isModelPage: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private stateSource = new BehaviorSubject<State>(initialState);
  currentState = this.stateSource.asObservable();

  updateState(newState: any) {
    this.stateSource.next(newState);
  }
}
