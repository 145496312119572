<!-- <div [class]="'py-1' +(category.open ? ' border-b border-gray-300' : '')"> -->
<div [class]="'py-1'">
    <div class="flex justify-between w-full">
        <a class="text-gray-700 hover:text-gray-600 cursor-pointer w-4/5" [routerLink]="[getLink(category)]"
            [routerLinkActive]="'font-bold text-amber-950 hover:text-amber-950'">
            <span>
                {{ category.assemblyGroupName }}
            </span>
        </a>
        @if (category.children?.length) {
        <div class="pt-0.5 h-fit cursor-pointer" (click)="toggle()">
            <i [class]="'text-gray-700 fa-solid' + (category.open ? ' fa-angle-up' : ' fa-angle-down')"></i>
        </div> }
        @else {
        <div class="pt-0.5 h-fit" (click)="toggle()">
            <i [class]="'text-gray-700 fa-solid invisible' + (category.open ? ' fa-angle-up' : ' fa-angle-down')"></i>
        </div>}
    </div>


    <div [@slideDown]="category.open ? 'open' : 'closed'" class="pl-4">
        @for (subCategory of category.children; track $index) {
        <app-side-nav-item [category]="subCategory" (toggleCategoryEmitter)="toggleCategory($index)" [modelId]="modelId"
            [isModel]="isModel"></app-side-nav-item>
        }
    </div>
</div>