import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CategoryNode } from '../../home/home.component';

@Component({
  selector: 'app-side-nav-item',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './side-nav-item.component.html',
  styleUrl: './side-nav-item.component.scss',
  animations: [
    trigger('slideDown', [
      state(
        'closed',
        style({
          height: '0',
          overflow: 'hidden',
          opacity: '0',
        })
      ),
      state(
        'open',
        style({
          height: '*',
          opacity: '1',
        })
      ),
      transition('closed <=> open', animate('300ms ease-in-out')),
    ]),
  ],
})
export class SideNavItemComponent {
  @Input()
  category: any;
  @Output()
  toggleCategoryEmitter = new EventEmitter();
  @Input()
  isModel: boolean = false;
  @Input()
  modelId?: number;

  getLink(category: CategoryNode): string {
    const base = 'category/' + category.assemblyGroupNodeId;
    if (this.isModel && this.modelId)
      return 'model/' + this.modelId + '/' + base;
    return base;
  }

  toggle() {
    this.toggleCategoryEmitter.emit();
  }

  toggleCategory(index: number): void {
    const category = this.category.children[index];
    if (category.children?.length) {
      category.open = !category.open;
    }
  }
}
